import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import HeroSection from '@features/product-page-components/hero-section/HeroSection';
import PartnersSlider from '@components/partners-slider/partners-slider';
import HealthFact from '@features/product-page-components/health-fact/health-fact';
import HappyGutSection from '@features/product-page-components/happy-gut-section/HappyGutSection';
import HighStrengthFormula from '@features/product-page-components/high-strength-formula/high-strength-formula';
import BenefitsSection from '@features/product-page-components/benefits-section/BenefitsSection';
import NutritionInformationSection from '@features/product-page-components/nutritional-information-section/nutritional-information-section';
import Reviews from '@components/reviews/reviews';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
import RecommendedProductSection from '@features/product-page-components/recomended-product-section/recomended-product-section';
import JoinTheCommunity from '@components/join-the-community/join-the-community';
import { graphql } from 'gatsby';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
import BackedByScienceSection from '@src/components/backed-by-sience/backed-by-science-section';

export const data = graphql`
  query($path: String!) {
    directusProductPageNewTemplate(path: { eq: $path }) {
      sku
      page_background_color
      plan_details
      backed_by_science_header
      backed_by_science_items {
        title
        studies
        participants
        description
        link
      }
      backed_by_science_subheader
      benefits_section_header
      benefits_section_left_column_header
      benefits_section_left_column_items {
        text
      }
      benefits_section_pill_image {
        filename_disk
      }
      benefits_section_right_column_header
      benefits_section_right_column_items {
        text
      }
      benefits_section_subheader
      faqs {
        question
        answer
      }
      happy_gut_header
      happy_gut_items {
        title
        description
        image
      }
      happy_gut_subheader
      health_facts_bottom_text
      health_facts_header
      health_facts_text
      hero_benefits {
        text
      }
      hero_header
      hero_images {
        filename_disk
        alt
      }
      hero_text
      hero_why_header
      hero_why_items {
        header
        text
        items {
          text
        }
      }
      high_strength_formula_header
      high_strength_formula_items {
        text
        header
        textBottom
        image
      }
      high_strength_formula_subheader
      nutrition_information_header
      nutrition_information_text
      page_description
      page_title
      path
      recommended_product_header
      recommended_products {
        id
        name
        identifier
        best_seller
        background_color
        filename_disk
        low_stock
        out_of_stock
        route
        description
        digital_product
        name
        selling_fast
        short_description
        status
      }
      reviews_items {
        reviewerName
        review
      }
      reviews_title
      status
      table_column_names
      table_description
      table_items {
        name
        quantity
        nrv
        description
      }
      table_title
    }
  }
`;

const NewProductTemplate: React.FC<any> = ({ data }) => {
  const { dataPlans: productPlans, loading: isLoadingPlans } = useGetProductPlansData(
    data?.directusProductPageNewTemplate?.sku
  );

  return (
    <Layout>
      <div
        style={{
          backgroundColor: data.directusProductPageNewTemplate.page_background_color,
          paddingTop: '60px',
        }}
      >
        <SEO
          title={data.directusProductPageNewTemplate.page_title}
          description={data.directusProductPageNewTemplate.page_description}
        />
        <HeroSection
          productPlans={productPlans?.product_plans}
          isLoading={isLoadingPlans}
          planDetails={data.directusProductPageNewTemplate.plan_details}
          backgroundColor={data.directusProductPageNewTemplate.page_background_color}
          benefitsList={data.directusProductPageNewTemplate.hero_benefits?.map(
            ({ text }: any) => text
          )}
          heroTitle={data.directusProductPageNewTemplate.hero_header}
          heroText={data.directusProductPageNewTemplate.hero_text}
          heroWhyHeader={data.directusProductPageNewTemplate.hero_why_header}
          heroImages={data.directusProductPageNewTemplate.hero_images}
          whyChooseItems={data.directusProductPageNewTemplate.hero_why_items}
        />
        <PartnersSlider />
        <HealthFact
          header={data.directusProductPageNewTemplate.health_facts_header}
          text={data.directusProductPageNewTemplate.health_facts_text}
          bottomText={data.directusProductPageNewTemplate.health_facts_bottom_text}
        />
        <HappyGutSection
          title={data.directusProductPageNewTemplate.happy_gut_header}
          subtitle={data.directusProductPageNewTemplate.happy_gut_subheader}
          items={data.directusProductPageNewTemplate.happy_gut_items}
        />
        <HighStrengthFormula
          title={data.directusProductPageNewTemplate.high_strength_formula_header}
          subtitle={data.directusProductPageNewTemplate.high_strength_formula_subheader}
          items={data.directusProductPageNewTemplate.high_strength_formula_items}
        />
        <BenefitsSection
          title={data.directusProductPageNewTemplate.benefits_section_header}
          subtitle={data.directusProductPageNewTemplate.benefits_section_subheader}
          leftTitle={data.directusProductPageNewTemplate.benefits_section_left_column_header}
          leftItems={data.directusProductPageNewTemplate.benefits_section_left_column_items?.map(
            (item: any) => item.text
          )}
          rightTitle={data.directusProductPageNewTemplate.benefits_section_right_column_header}
          rightItems={data.directusProductPageNewTemplate.benefits_section_right_column_items?.map(
            (item: any) => item.text
          )}
          image={data.directusProductPageNewTemplate.benefits_section_pill_image?.filename_disk}
        />
        <BackedByScienceSection
          title={data.directusProductPageNewTemplate.backed_by_science_header}
          subtitle={data.directusProductPageNewTemplate.backed_by_science_subheader}
          items={data.directusProductPageNewTemplate.backed_by_science_items}
          backgroundColor={data.directusProductPageNewTemplate.page_background_color}
        />
        <NutritionInformationSection
          sectionTitle={data.directusProductPageNewTemplate.nutrition_information_header}
          sectionDescription={data.directusProductPageNewTemplate.nutrition_information_text}
          tableTitle={data.directusProductPageNewTemplate.table_title}
          tableDescription={data.directusProductPageNewTemplate.table_description}
          tableItems={data.directusProductPageNewTemplate.table_items?.map((item: any) => ({
            id: item.name,
            name: item.name,
            quantity: item.quantity,
            nrv: item.nrv,
            description: item.description,
          }))}
          tableColumnNames={data.directusProductPageNewTemplate.table_column_names}
        />
        <Reviews
          title={data.directusProductPageNewTemplate.reviews_title}
          items={data.directusProductPageNewTemplate.reviews_items?.map((item: any) => ({
            reviewerName: item.reviewerName,
            review: item.review,
          }))}
          hideTrustPilot
          hideImage
          showReview
          variationLightYellow
          backgroundColor="rgba(254, 246, 220, 0.8)"
        />
        <StillHaveQuestion
          accordion={data.directusProductPageNewTemplate.faqs?.map((item: any) => ({
            question: item.question,
            answer: item.answer,
          }))}
          description={`<p>If you don’t see your question then please get in touch <a href="/contact">here</a>.</p>`}
        />
        <RecommendedProductSection
          products={data.directusProductPageNewTemplate.recommended_products}
          backgroundColor="rgb(249, 244, 234)"
        />
        <JoinTheCommunity />
      </div>
    </Layout>
  );
};

export default NewProductTemplate;
