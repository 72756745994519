import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Card } from 'vitl-component-library';

import Image from '@src/components/image/image';
import { limitTheText } from '@src/utils/text';

import styles from './high-strength-formula.module.scss';

interface IProps {
  header: string;
  text: string;
  textBottom: string;
  image: string;
}

const HighStrenghtFormulaItem: React.FC<IProps> = ({ header, text, textBottom, image }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  return (
    <Card borderRadius shadow className={styles.slideCard}>
      <>
        <Image filename={image} alt={header} height={130} />
        <div className={styles.body}>
          <h5>{limitTheText(header, isMobile ? 56 : 80)}</h5>
          <p>{limitTheText(text, 156)}</p>
          <p>{limitTheText(textBottom, 156)}</p>
        </div>
      </>
    </Card>
  );
};

export default HighStrenghtFormulaItem;
