import React from 'react';

import RecommendedProducts from '@src/features/cross-sell/recommended-products/recommended-products';
import { IDirectusShopItem } from '@src/types/directus/shop';
import { IBasketItem } from '../../../types/basket';
import { getPriceForAddOrRemoveItem } from '@src/services/tracking/ga';
import { trackEvent } from '@src/services/tracking/tracking';

interface IProps {
  backgroundColor: string;
  products: IDirectusShopItem[];
}

import styles from './recomended-product-section.module.scss';

const RecommendedProductSection: React.FC<IProps> = ({ backgroundColor, products }) => {
  const onAddToBasketCallback = (
    activeSlide: number,
    currency: string,
    lastModifiedItem?: IBasketItem,
    sku?: string
  ) => {
    const eventName = `productpage_crosssell_add_${activeSlide}_${sku}`;
    const value = getPriceForAddOrRemoveItem(lastModifiedItem || null);
    trackEvent(eventName, {
      currency,
      value,
      items: [lastModifiedItem],
    });
  };
  return (
    <section className={styles.section} style={{ backgroundColor }}>
      <h2>Other customers bought</h2>
      <RecommendedProducts
        products={products}
        isAddToBasketView
        height={480}
        onAddToBasketCallback={onAddToBasketCallback}
      />
    </section>
  );
};

export default RecommendedProductSection;
