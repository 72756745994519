import React, { FC } from 'react';
import { Section } from 'vitl-component-library';

import styles from './health-fact.module.scss';

interface IHealthFactProps {
  header: string;
  text: string;
  bottomText: string;
}

const HealthFact: FC<IHealthFactProps> = ({ header, text, bottomText }) => (
  <Section backgroundColor="rgba(255,255,255, 0.50)">
    <div className={styles.healthFact}>
      <h2>{header}</h2>
      <p>{text}</p>
      <span>{bottomText}</span>
    </div>
  </Section>
);

export default HealthFact;
