import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AddToBacketSticky,
  AddToBasket,
  Image,
  List,
  Section,
  SwiperComponent,
} from 'vitl-component-library';

import { IProductPlan } from '@src/types/product';
// import Accordion from '@src/components/accordion/accordion'; //! CHANGE ME with a CL version
import styles from './hero-section.module.scss';
import { useAddToBasket } from '@src/features/shop/hooks/useAddToBasket';
import { getCookie } from '@src/services/cookies';
import { COOKIES } from '@src/constants/cookies';
import { GlobalContext } from '@src/store/global-state';
import { useIntersectionObserver } from '@hooks/useIntersectionObserver';
import { useMediaQuery } from 'react-responsive';
import WhyChooseAccordionItemHeader from './accordion-items/WhyChooseAccordionItemHeader';
import { trackEvent } from '@services/tracking/tracking';
import { useQueryParamBoolean } from '@src/hooks/useQueryParams';

interface IHeroSectionProps {
  productPlans?: IProductPlan[];
  planDetails: string;
  isLoading: boolean;
  backgroundColor: string;
  benefitsList: string[];
  heroTitle: string;
  heroText: string;
  heroWhyHeader: string;
  heroImages: any;
  whyChooseItems: any[];
}

const PRESELECTED_PLAN = 'subscription';

const HeroSection: FC<IHeroSectionProps> = ({
  productPlans = [],
  backgroundColor,
  isLoading,
  planDetails,
  benefitsList,
  heroTitle,
  heroText,
  heroWhyHeader,
  heroImages,
  whyChooseItems,
}) => {
  const [selectedImage, setSelectedImage] = useState(heroImages[0]);
  // We need to set the initial one to either subscription or oneOff, depends on what is preselected for AddToBasket (default subscription)
  const [selectedPlan, setSelectedPlan] = useState<IProductPlan | undefined>();
  const { setHasUserAddedProductToBasket, setShowBasket, showBasket } = useContext(GlobalContext);

  const WHY_CHOOSE_ITEMS = whyChooseItems.map(item => {
    return {
      question: <WhyChooseAccordionItemHeader text={item.header} />,
      answer: (
        <div>
          {item.text ? <p>{item.text}</p> : null}
          {item.items?.length ? (
            <List
              icon="check"
              iconColor="#333333"
              fontSize="medium"
              items={item.items.map((listItem: any) => listItem.text)}
              className={styles.whyChooseList}
            />
          ) : null}
        </div>
      ),
    };
  });

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const isTablet = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const hasRouteOneOff = useQueryParamBoolean('oneOff');

  useEffect(() => {
    if (!productPlans || !productPlans.length) {
      return;
    }
    if (hasRouteOneOff) {
      const oneOffPlan = productPlans.find(plan => !plan.deliveryFrequency);
      setSelectedPlan(oneOffPlan);
      return;
    }
    const preselectedPlan = productPlans.find(plan => {
      if (PRESELECTED_PLAN === 'subscription') {
        return plan.deliveryFrequency;
      }
      if (PRESELECTED_PLAN === 'oneOff') {
        return !plan.deliveryFrequency;
      }
    });
    setSelectedPlan(preselectedPlan);
  }, [productPlans, hasRouteOneOff]);

  const addToBasketRef = useRef(null);

  const addToBasketObserver = useIntersectionObserver(addToBasketRef, {
    threshold: 0,
    root: null,
    rootMargin: '0%',
  });

  const isAddToBasketVisible = addToBasketObserver?.isIntersecting || false;

  const onAddItemToCartCompleted = () => {
    setHasUserAddedProductToBasket(true);
    setShowBasket(true);
  };

  const { addItemToBasket, loading: isLoadingAddToBasket } = useAddToBasket(
    onAddItemToCartCompleted
  );

  const handleAddToBasket = (sku: string) => {
    addItemToBasket({
      variables: {
        basketId: getCookie(COOKIES.basketId),
        sku,
        quantity: 1,
      },
    });
  };

  const handlePlanClick = (plan: IProductPlan) => {
    setSelectedPlan(plan);
    const planEventName = `productpage_${
      plan.deliveryFrequency ? 'subscribe' : 'onetime'
    }_selected`;
    trackEvent(planEventName);
  };

  return (
    <>
      <Section backgroundColor={backgroundColor} className={styles.section}>
        <div className={styles.row}>
          <div className={styles.leftSection}>
            <div className={styles.leftSectionContent}>
              {isTablet && (
                <Image
                  filename={selectedImage.filename_disk}
                  alt={selectedImage.alt}
                  className={styles.heroImage}
                  width={480}
                  mediaUrl={process.env.GATSBY_MEDIA_URL}
                />
              )}
              <SwiperComponent
                className={styles.slider}
                paginationType="bullets"
                showArrows={false}
                pagination={false}
                allowTouchMove={!isTablet}
                slideToClickedSlide={!isTablet}
                breakpoints={{
                  320: {
                    slidesOffsetBefore: 10,
                    slidesOffsetAfter: 10,
                    slidesPerView: 1.15,
                  },
                  375: {
                    slidesOffsetBefore: 10,
                    slidesOffsetAfter: 10,
                    slidesPerView: 1.35,
                  },
                  414: {
                    slidesOffsetBefore: 15,
                    slidesOffsetAfter: 15,
                    slidesPerView: 1.45,
                  },
                  570: {
                    slidesOffsetBefore: 60,
                    slidesOffsetAfter: 60,
                    spaceBetween: 16,
                    slidesPerView: 1.8,
                  },
                  768: {
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                    slidesPerView: 3,
                  },
                }}
              >
                {heroImages.map((heroImage: any) => (
                  <div
                    key={heroImage.filename_disk}
                    className={`${styles.heroSlide} ${
                      heroImage === selectedImage && isDesktop ? styles.selectedImageWrapper : ''
                    }`}
                    onClick={() => setSelectedImage(heroImage)}
                  >
                    <Image
                      filename={heroImage.filename_disk}
                      alt={heroImage.alt}
                      width={isDesktop ? 85 : 360}
                      height={isDesktop ? 85 : 360}
                      mediaUrl={process.env.GATSBY_MEDIA_URL}
                    />
                  </div>
                ))}
              </SwiperComponent>
            </div>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.rightSectionContent}>
              <h1 className={styles.productName}>{heroTitle}</h1>
              <p className={styles.productDescription}>{heroText}</p>
              <div className={styles.benefitsList}>
                <List
                  items={benefitsList}
                  icon="check"
                  iconColor="#333333"
                  direction="horizontal"
                  fontSize="medium"
                  className={styles.goalsList}
                />
              </div>
              {productPlans && productPlans.length > 0 && (
                <div ref={addToBasketRef} className={styles.addToBasketWrapper}>
                  <AddToBasket
                    plans={productPlans}
                    onAddToBasketClick={handleAddToBasket}
                    isLoading={isLoadingAddToBasket}
                    onPlanClick={handlePlanClick}
                    planDetails={planDetails}
                    preselectedPlan={hasRouteOneOff ? 'oneOff' : PRESELECTED_PLAN}
                  />
                </div>
              )}
              <div className={styles.accordionWrapper}>
                <h6>{heroWhyHeader}</h6>
                <Accordion
                  isTransparent
                  isHtmlContent={false}
                  items={WHY_CHOOSE_ITEMS}
                  noIconBorder
                  withEffects
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      {!isAddToBasketVisible &&
      !isDesktop &&
      !showBasket &&
      addToBasketRef &&
      addToBasketRef.current &&
      selectedPlan ? (
        <AddToBacketSticky
          plan={selectedPlan}
          onAddToBasketClick={handleAddToBasket}
          className={styles.addToBasketSticky}
          isLoading={isLoadingAddToBasket}
        />
      ) : null}
    </>
  );
};

export default HeroSection;
