import React, { FC } from 'react';
import { Section, SwiperComponent } from 'vitl-component-library';
import HappyGutSectionItem from './HappyGutSectionItem';
import styles from './happy-gut-section.module.scss';

interface IHappyGutSectionProps {
  title: string;
  subtitle: string;
  items: any[];
}

const HappyGutSection: FC<IHappyGutSectionProps> = ({ title, subtitle, items }) => {
  return (
    <Section title={title} subTitle={subtitle} backgroundColor="rgba(255,255,255, 0.90)" className={styles.section}>
      <SwiperComponent
        paginationType="bullets"
        className={styles.swiper}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {items.map((item: any) => (
          <HappyGutSectionItem key={item.title} image={item.image} title={item.title} description={item.description} />
        ))}
      </SwiperComponent>
    </Section>
  );
};

export default HappyGutSection;
