import React, { FC } from 'react';
import { NutritionalInformationUI, Section } from 'vitl-component-library';
import styles from './nutritional-information-section.module.scss';

interface INutritionInformationSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  tableTitle: string;
  tableDescription: string;
  tableItems: any[];
  tableColumnNames: string;
}

const NutritionInformationSection: FC<INutritionInformationSectionProps> = ({
  sectionTitle,
  sectionDescription,
  tableTitle,
  tableItems,
  tableDescription,
  tableColumnNames,
}) => {
  const columnNames = tableColumnNames ? tableColumnNames.split(';') : undefined;

  return (
    <Section backgroundColor="rgba(255,255,255, 0.5)">
      <div className={styles.row}>
        <div className={styles.nutritionalInfoTableCol}>
          <NutritionalInformationUI
            title={tableTitle}
            description={tableDescription}
            items={tableItems}
            tableColumnNames={columnNames}
          />
        </div>
        <div className={styles.nutritionalInfoTextCol}>
          <h3>{sectionTitle}</h3>
          <p dangerouslySetInnerHTML={{ __html: sectionDescription }} />
        </div>
      </div>
    </Section>
  );
};
export default NutritionInformationSection;
