import React, { FC } from 'react';

import styles from './happy-gut-section.module.scss';
import Image from '@src/components/image/image';

interface IHappyGutSectionItemProps {
  image: string;
  title: string;
  description: string;
}

const HappyGutSectionItem: FC<IHappyGutSectionItemProps> = ({ image, title, description }) => {
  return (
    <div className={styles.happyGutItem}>
      <Image filename={image} width={160} />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default HappyGutSectionItem;
