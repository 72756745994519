import React, { FC } from 'react';
import styles from './accordion-items.module.scss';

interface IWhyChooseAccordionItemHeaderProps {
  text: string;
}

const WhyChooseAccordionItemHeader: FC<IWhyChooseAccordionItemHeaderProps> = ({ text }) => (
  <div className={styles.whyChooseAccordionItemHeader}>{text}</div>
);

export default WhyChooseAccordionItemHeader;
