import React, { FC } from 'react';
import { Section, SwiperComponent } from 'vitl-component-library';
import HighStrenghtFormulaItem from './high-strenght-formula-item';

import styles from './high-strength-formula.module.scss';
import { useMediaQuery } from 'react-responsive';
import { data } from './mock';

interface IHighStrengthFormulaProps {
  title: string;
  subtitle: string;
  items: any[];
}

const HighStrengthFormula: FC<IHighStrengthFormulaProps> = ({ title, subtitle, items }) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  return (
    <Section title={title} subTitle={subtitle} backgroundColor="rgba(255,255,255, 0.20)" className={styles.section}>
      <SwiperComponent
        className={styles.slider}
        paginationType="bullets"
        showArrows={false}
        pagination={false}
        allowTouchMove={!isDesktop}
        slideToClickedSlide={!isDesktop}
        breakpoints={{
          320: {
            slidesPerView: 1.15,
            slidesOffsetBefore: 8,
            slidesOffsetAfter: 8,
          },
          375: {
            slidesPerView: 1.35,
            slidesOffsetBefore: 15,
            slidesOffsetAfter: 15,
          },
          414: {
            slidesPerView: 1.45,
            slidesOffsetBefore: 15,
            slidesOffsetAfter: 15,
          },
          768: {
            slidesPerView: 2.4,
            slidesOffsetBefore: 30,
            slidesOffsetAfter: 30,
          },
          1024: {
            slidesPerView: 3,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
        }}
      >
        {items.map(item => (
          <HighStrenghtFormulaItem
            key={item.header}
            header={item.header}
            text={item.text}
            textBottom={item.textBottom}
            image={item.image}
          />
        ))}
      </SwiperComponent>
    </Section>
  );
};

export default HighStrengthFormula;
